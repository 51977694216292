.logos {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
.logos-slide img {
  height: 80px;
}

.logos-slide {
  display: inline-block;
  animation: scrollAnimation 60s linear infinite; /* Adjust the duration as needed */
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -500%
    ); 
  }
}
