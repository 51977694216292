.uk-container {
  box-sizing: content-box;
  display: flow-root;
  margin-left: auto;
  margin-right: auto;
  max-width: 1800px;
  padding: 20px 40px 20px 40px;
}
.uk-container-main {
  box-sizing: content-box;
  display: flow-root;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  padding: 20px 40px 20px 40px;
}

.coverImg {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-image: url("../../public/cover.jpg");
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .uk-container {
    box-sizing: content-box;
    display: flow-root;
    margin-left: auto;
    margin-right: auto;
    max-width: 1550px;
    padding: 10px 10px 10px 10px;
  }
  .uk-container-main {
    box-sizing: content-box;
    display: flow-root;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    padding: 10px;
  }
}
